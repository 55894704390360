
























import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
export default Vue.extend({
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    imageUrl(item) {
      return coreApiClient.makeDownloadUrl("paymentTransactions", `${this.item._id}/billImages/${item.uuid}`);
    },
    downloadFile(item) {
      location.href = coreApiClient.makeDownloadUrl("paymentTransactions", `${this.item._id}/billImages/${item.uuid}`);
    },
    deleteBtnOptions(fileItem) {
      const item = this.item;
      return {
        attrs: {
          block: true,
          color: "error",
        },
        content: {
          text: "Xóa",
        },
        target: {
          confirm: {
            content: {
              title: {
                html: "Xác nhận",
              },
              content: {
                html: "Bạn có chắc chắn muốn xóa ảnh này?",
              },
            },
            on: {
              async xYes({ self }) {
                self.yesBtnContainer.value.loading.value = true;
                item.billImages = item.billImages.filter((x) => x.uuid !== fileItem.uuid);
                const result = await coreApiClient.call(
                  "paymentTransactions",
                  "update",
                  {
                    id: item._id,
                  },
                  {
                    data: {
                      billImages: item.billImages,
                    },
                  }
                );
                self.yesBtnContainer.value.loading.value = false;
                if (result) {
                  self.hide();
                }
              },
            },
          },
        },
      };
    },
  },
});








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import BillImagesVue from "./transactions/partials/BillImages.vue";
import { displayItemsWithName, displayText } from "@/utils/marketItemDisplay";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý giao dịch",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              transactionId: {
                attrs: {
                  label: "Mã giao dịch",
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              amount: {
                type: "number",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (>=)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (<=)",
                    },
                  },
                },
              },
              paymentPartnerId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đối tác",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentPartners", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              paymentAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản ngân hàng",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        items.forEach((item) => {
                          item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              paymentReceiveAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản ngân hàng nhận tiền",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentReceiveAccounts", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        items.forEach((item) => {
                          item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              shopId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("shops", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktPlatformId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              marketId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("markets", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              countryId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Quốc gia",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("countries", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              customerPaidFor: {
                type: "boolean",
                attrs: {
                  label: "Khách hàng thanh toán hộ",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            transactionId: {
              text: "Mã giao dịch",
              sortable: true,
            },
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            description: {
              text: "Mô tả",
            },
            amount: {
              text: "Số tiền",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            exchangeRate: {
              text: "Tỷ giá",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            paymentPartner: {
              text: "Đối tác",
              options: {
                label: true,
                subProp: "paymentPartner.name",
                sortBy: "paymentPartnerId",
              },
            },
            paymentAccount: {
              text: "TKNH",
              options: {
                label: true,
                sortBy: "paymentAccountId",
                preTransform(value) {
                  return `${value.name} - ${value.cardNumber} - ${value.bankName}`;
                },
              },
            },
            paymentReceiveAccount: {
              text: "TKNH nhận tiền",
              options: {
                label: true,
                sortBy: "paymentReceiveAccountId",
                preTransform(value) {
                  return value && `${value.name} - ${value.cardNumber} - ${value.bankName}`;
                },
              },
            },
            shop: {
              text: "Shop",
              options: {
                label: true,
                transform(value) {
                  return displayText(value, "name");
                },
                sortBy: "shopId",
              },
            },
            market: {
              text: "Thị trường",
              options: {
                label: true,
                subProp: "market.name",
                sortBy: "marketId",
              },
            },
            country: {
              text: "Quốc gia",
              options: {
                label: true,
                subProp: "country.name",
                sortBy: "countryId",
              },
            },
            mktPlatform: {
              text: "Nền tảng MKT",
              options: {
                label: true,
                subProp: "mktPlatform.name",
                sortBy: "mktPlatformId",
              },
            },
            customerPaidFor: {
              text: "Khách hàng thanh toán hộ",
              options: {
                filter: "boolean",
              },
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("paymentTransactions", "import", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const lines = [`Cập nhật thành công: ${result.updatedIndexes.length}`, "", `Các hàng bị lỗi ${result.errorIndexes.join(",")}`];
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("paymentTransactions", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "description",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              importBill: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-stamper",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Kế toán xác nhận",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Kế toán xác nhận",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.orderId;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    files: {
                                      type: "file",
                                      attrs: {
                                        multiple: true,
                                        label: "Hóa đơn",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Xác nhận",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              console.log(data);
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "paymentTransactions",
                                    "addBillImages",
                                    {
                                      id: item._id,
                                    },
                                    data,
                                    true
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              billImages: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-list-box-outline",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Bill xác nhận",
                    },
                  },
                  dialog: {
                    content: {
                      toolbar: {
                        title: "Bill xác nhận",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.orderId;
                        },
                      },
                      content: {
                        type: BillImagesVue,
                        makeAttrs(attrs, dialog) {
                          attrs.item = dialog.context().context().item;
                          return attrs;
                        },
                        attrs: {},
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("paymentTransactions", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("paymentTransactions", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "paymentTransactions",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("paymentTransactions", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              transactionId: {
                attrs: {
                  label: "Mã giao dịch",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian",
                },
              },
              amount: {
                type: "number",
                attrs: {
                  label: "Số tiền",
                },
              },
              exchangeRate: {
                type: "number",
                attrs: {
                  label: "Tỷ giá",
                },
              },
              paymentPartnerId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đối tác",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentPartners", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              paymentAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "TKNH",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        items.forEach((item) => {
                          item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              paymentReceiveAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "TKNH nhận tiền",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("paymentReceiveAccounts", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        items.forEach((item) => {
                          item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              shopId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("shops", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              marketId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("markets", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              countryId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Quốc gia",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("countries", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktPlatformId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              customerPaidFor: {
                type: "boolean",
                attrs: {
                  label: "Khách hàng thanh toán hộ",
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["transactionId", "time", "description", "amount", "exchangeRate", "paymentPartnerId", "paymentAccountId", "paymentReceiveAccountId", "shopId", "marketId", "countryId", "mktPlatformId", "customerPaidFor"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["transactionId", "time", "description", "amount", "exchangeRate", "paymentPartnerId", "paymentAccountId", "paymentReceiveAccountId", "shopId", "marketId", "countryId", "mktPlatformId", "customerPaidFor"],
              },
            },
            delete: {
              confirmDisplayField: "description",
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
